.footerContainer {
    height: 130px;
    padding: 0 75px;
    background-color: #0D1115;
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 0;
    margin-top: auto;
    z-index: 50;

    .logoImage {
        height: 50px;
    }
}

@media (max-width: 1440px) {
    .footerContainer {
        position: inherit;
        min-height: 130px;
    }
}

@media (max-width: 1167px) {
    .footerContainer {
        height: 216px;
        flex-direction: column;
        padding: 40px 0 30px 0;
        height: auto;

        div {
            order: 0;
            margin-bottom: 30px;
        }
    
        p {
            order: 1;
            line-height: 30px;
        }
    }
}