#section-9 {
    margin-bottom: 100px;

    .main-section {
        min-height: 877px;
        width: 95%;
        border-radius: 0px 200px 0px 0px;
        background: #0E1216;
        position: relative;
        padding: 122px 190px;
    }

    .left-overlay {
        position: absolute;
        height: 100%;
        width: 300px;
        top: 0;
        left: -300px;
        background-color: #0E1216;
    }

    .description {
        color: #BAC2CB;
        font-size: 16px;
        font-weight: 400;
        line-height: 30px;
        margin-bottom: 0;
    }

    form {
        gap: 30px;
        margin-top: 65px;

        label {
            margin-bottom: 8px;
            color: #FFF;
            font-size: 16px;
            font-weight: 600;
            line-height: normal;
        }

        input,
        textarea {
            border: 1px solid #737C85;
            height: 56px;
            background-color: transparent;
            color: #FFF;

            &::placeholder {
                color: #BAC2CB;
            }
        }

        textarea {
            height: 165px;
        }

        .submit-button {
            border-radius: 8px;
            background: #FFF;
            width: 250px;
            height: 56px;
            margin-left: auto;
            color: #12171C;
            font-size: 16px;
            font-weight: 600;
        }
    }

    @media (max-width: 1440px) {
        margin-bottom: 50px;

        .main-section {
            padding: 122px 50px;
            min-height: auto;
        }
    }

    @media (max-width: 1167px) {
        .main-section {
            width: 100%;
            margin-top: 50px;
            padding: 100px 30px;
        }

        .fields-row {
            flex-direction: column;
            gap: 30px;
        }
    }
}