#section-4 {
    .main-section {
        display: flex;
        justify-content: space-around;
        padding-left: 80px;
        padding-top: 150px;
    }

    .section-info {
        padding-top: 100px;
    }

    .multi-buttons-wrapper {
        display: grid;
        grid-template-columns: 1fr 1fr;
        column-gap: 2em;
        row-gap: 1em;
        height: 50%;
        align-self: center;
        padding-right: 45px;

        .item {
            display: flex;
            align-items: center;
            gap: 1em;
            background-color: #191F25;
            padding: 1em;
            border-radius: 1em;
            cursor: pointer;
            border: 1px solid #191F25;

            &:hover {
                background-color: #12171C;
                border: 1px solid white;
            }
        }
    }


    .multi-buttons-wrapper>div>p {
        margin-left: auto;
    }

    .multi-buttons-wrapper>div.title {
        grid-column: span 2;
        font-size: 24px;
        background-color: #12171C;
        text-transform: none;
        font-weight: 500;
    }

    @media (max-width: 1167px) {
        .main-section {
            padding: 0 50px 120px 50px;
        }

        .multi-buttons-wrapper {
            padding: 0;
        }

        .section-info {
            width: 100%;
        }

        .cover-image {
            display: none;
        }
    }

    @media (max-width: 650px) {
        .main-section {
            padding: 100px 30px;
            padding-bottom: 50px;
        }

        .multi-buttons-wrapper {
            display: flex;
            flex-direction: column;
        }

        .section-info {
            padding-top: 0;
        }
    }
}