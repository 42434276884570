* {
  font-family: 'Barlow';
}

.home {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  background-color: #12171C;
  position: relative;
}

.vertical-tabs {
  width: 100vw;
  display: flex;
  height: 100vh;

  @media (max-width: 1440px) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 1167px) {
    display: block;
  }
}