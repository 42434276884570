#section-6 {
    .main-section {
        height: 662px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        padding: 0 100px;
    }

    .description {
        color: #BAC2CB;
        font-size: 16px;
        line-height: 30px;
        margin: 0;
    }

    .contents-row {
        display: flex;
        align-items: center;
        margin-top: 65px;
    }

    .item {
        width: 20%;
        height: 298px;
        border-radius: 50px;
        background-color: #191F25;
        margin-right: 28px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }

    .item-cover {
        flex: 1;
    }

    .item-info {
        width: 100%;
        height: 96px;
        background-color: #0E1216;

        p {
            color: #FFF;
            text-align: center;
            font-size: 16px;
            line-height: 24px;
            margin: 0;
            text-align: center;
        }
    }

    @media (max-width: 1440px) {
        .main-section {
            padding: 0 30px;
        }

        .item {
            margin-right: 10px;
        }
    }

    @media (max-width: 1167px) {
        margin-top: 65px;

        .main-section {
            height: auto;
        }

        .contents-row {
            flex-direction: column;
            align-items: center;
            gap: 20px;
        }

        .item {
            width: 100%;
            margin-right: 0;
        }
    }
}