.item {
    background-color: #191F25;
    padding: 1em;
    border-radius: 1em;
    cursor: pointer;
    border: 1px solid #191F25;
    height: fit-content;
    display: flex;
    flex-direction: column;
    align-items: center;

    .itemInfo {
        display: flex;
        align-items: center;
        gap: 1em;
        width: 100%;

        span {
            text-transform: uppercase;
        }
    }

    &:hover {
        background-color: #12171C;
        border: 1px solid white;
    }

    .leftArrow {
        margin-left: auto;
        margin-bottom: 0;
    }

    .coverImg {
        margin-top: 50px;
        display: none;
    }

    .bottomArrow {
        display: none;
        margin-left: auto;
    }

    @media (max-width: 1167px) {
        .coverImg {
            display: block;
        }

        .leftArrow {
            display: none;
        }

        .bottomArrow {
            display: block;
            transition-duration: 0.3s;
        }

        .active {
            transform: rotate(180deg);
        }
    }
}