.side-nav-menu {
    display: flex;
    height: 100vh;
    align-items: center;
    justify-content: space-between;
    z-index: 999;
    position: fixed;

    .list-group {
        margin-left: 80px;

        a {
            white-space: nowrap;
        }
    }

    #menuToggle {
        display: none;
    }

    .logo-img {
        max-height: 74px;
        position: absolute;
        top: 71px;
        left: 80px;
    }

    .mobile-logo-img {
        display: none;
    }

    .mobile-menu-footer {
        display: none;
    }

    @media (max-width: 1440px) {
        height: auto;
        width: 100%;
        padding: 20px 50px;
        background-color: #12171C;

        .logo-img {
            position: initial;
        }

        .list-group {
            flex-direction: row;
            margin-left: 0;
            flex: 1;
            justify-content: center;

            .list-group-item {
                width: auto;
                border: 0;
                padding: 0 12px;
                padding-top: 20px;
                border-top: 1px solid #293139;
            }

            .list-group-item.active {
                border-top: 2px solid #01E4FF;
            }
        }
    }

    @media (max-width: 1167px) {
        transition-duration: 0.4s;
        position: fixed;
        // transform: translate(0, 10%);
        top: 0;
        align-items: center;
        min-height: 115px;

        .nav-main-wrapper {
            height: 100vh;
            width: 100vw;
            left: 0;
            top: 0;
            transform: translate(0, -100%);
            position: absolute;
            transition-duration: 0.3s;
            background-color: #12171C;

            .mobile-logo-img {
                display: block;
                margin: 21px 22px;
                height: 60px;
            }
        }

        .mobile-menu-footer {
            display: block;
            margin-top: auto;
            bottom: 0;
            position: absolute;
            width: 100%;
            margin: 0;
        }

        .list-group {
            flex-direction: column;
            flex: 1;
            margin-top: 35px;
            margin-left: 40px;

            .list-group-item {
                border-left: 1px solid #293139;
                border-top: 0;
                padding: 10px 17px;
            }

            .list-group-item.active {
                border-left: 2px solid #01E4FF;
                border-top: 0;
            }
        }

        #menuToggle {
            display: block;
            margin-left: auto;
        }

        .logo-img {
            top: 21px;
            left: 21px;
            height: 60px;
        }
    }
}


// Menu Toggle

#menuToggle {
    display: block;
    position: relative;

    z-index: 10;

    -webkit-user-select: none;
    user-select: none;
}

#menuToggle input {
    display: block;
    width: 40px;
    height: 32px;
    position: absolute;
    top: -7px;
    left: -5px;

    cursor: pointer;

    opacity: 0;
    /* hide this */
    z-index: 1000;
    /* and place it over the hamburger */

    -webkit-touch-callout: none;
}

/*
  * Just a quick hamburger
  */
#menuToggle span {
    display: block;
    width: 24px;
    height: 3px;
    margin-bottom: 4px;
    position: relative;

    background: #01E4FF;
    border-radius: 3px;

    z-index: 999;

    transform-origin: 4px 0px;

    transition: transform 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        background 0.5s cubic-bezier(0.77, 0.2, 0.05, 1.0),
        opacity 0.55s ease;
}

#menuToggle span:first-child {
    transform-origin: 0% 0%;
}

#menuToggle span:nth-last-child(2) {
    transform-origin: 0% 100%;
}

/* 
  * Transform all the slices of hamburger
  * into a crossmark.
  */
#menuToggle input:checked~span {
    opacity: 1;
    transform: rotate(45deg) translate(-6px, -5px);
    background: #01E4FF;
}

/*
  * But let's hide the middle one.
  */
#menuToggle input:checked~span:nth-last-child(3) {
    opacity: 0;
    transform: rotate(0deg) scale(0.2, 0.2);
}

/*
  * Ohyeah and the last one should go the other direction
  */
#menuToggle input:checked~span:nth-last-child(2) {
    transform: rotate(-45deg) translate(-8px, 9px);
    background: #01E4FF;
}