#section-5 {
    .main-section {
        height: auto;
        display: flex;
        padding-right: 130px;
        padding-top: 50px;
    }

    .section-info {
        padding: 0 60px;
        padding-left: 120px;
        background-color: #0E1216;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;

        .left-overlay {
            position: absolute;
            height: 100%;
            width: 300px;
            top: 0;
            left: -300px;
            background-color: #0E1216;
        }
    }

    .cover-img {
        margin-bottom: -52px;
        margin-left: -20px;
        z-index: 10;
    }

    @media (max-width: 1440px) {
        .main-section {
            padding-bottom: 55px;
            padding-top: 100px;
        }
    }

    @media (max-width: 1167px) {
        .main-section {
            flex-direction: column;
            align-items: flex-start;
            padding-right: 0;
            height: auto;
            padding-top: 0;
        }

        .section-info {
            padding: 74px 40px;

            .left-overlay {
                display: none;
            }
        }

        .cover-img {
            height: 650px;
        }
    }

    @media (max-width: 650px) {
        .cover-img {
            width: 100%;
            height: auto;
        }
    }
}