@import '~bootstrap/scss/bootstrap.scss';


body {
  // --bs-font-sans-serif: "Barlow", sans-serif system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", "Noto Sans", "Liberation Sans", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  $font-family-base: "Barlow", sans-serif;
  $font-size-base: "Barlow", sans-serif;
}


.list-group {
  margin-left: 2em;
  --bs-list-group-color: #BAC2CB;
  --bs-list-group-active-color: #01E4FF;
  --bs-list-group-border-color: #293139;
  --bs-list-group-active-border-color: #01E4FF;
  --bs-list-group-bg: inherit;
  --bs-list-group-border-radius: 0;
  --bs-list-group-border-width: 2px;
  --bs-list-group-active-bg: inherit;
  --bs-list-group-action-hover-bg: inherit;
  --bs-list-group-action-hover-color: #FFFFFF;
  --bs-list-group-action-active-bg: inherit;
  --bs-list-group-action-active-color: #01E4FF;
  .list-group-item {
    border-top: 0;
    border-bottom: 0;
    border-right: 0;
    text-transform: uppercase;
    font-size: 1em;
    &:hover {
      font-weight: 600;
    }
  }
  .list-group-item.active {
    font-weight: 600;
  }
}