@import url("./PartialsStyle/hero-section.scss");
@import url("./PartialsStyle/about-section.scss");
@import url("./PartialsStyle/ourPeople-section.scss");
@import url("./PartialsStyle/services-section.scss");
@import url("./PartialsStyle/master-section.scss");
@import url("./PartialsStyle/bespoke-section.scss");
@import url("./PartialsStyle/training-section.scss");
@import url("./PartialsStyle/transparency-section.scss");
@import url("./PartialsStyle/form-section.scss");

.vertical-tabs {
  width: 100vw;
  display: flex;
  height: 100vh;

  @media (max-width: 1440px) {
    width: 100%;
    flex-direction: column;
  }

  @media (max-width: 1167px) {
    display: block;
  }
}

.panel-wrapper {
  flex: 1;
  padding-left: 290px;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;

  @media (max-width: 1440px) {
    padding-left: 0;
  }
}

.vertical-tabs .tab-panel {
  width: 100%;
  min-height: 100vh;

  @media (max-width: 1440px) {
    height: auto;
    min-height: auto;
  }
}

.section-titles {
    font-size: 24px;
    font-weight: 600;
    line-height: 30px;
    margin-bottom: 24px;
    position: relative;

    img {
        position: absolute;
        top: -13px;
        left: -8px;
    }
}
