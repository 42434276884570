#section-7 {
    .main-section {
        height: 950px;
        display: flex;
        margin-top: 100px;
    }

    .title-section {
        flex: 1;
    }

    .section-info {
        height: 100%;
        width: 60%;
        background-color: #0E1216;
        border-radius: 200px 0px 0px 0px;
        padding: 120px;
    }

    .step-item {
        gap: 44px;
        color: #BAC2CB;
        font-size: 16px;
        font-style: normal;
        line-height: 30px;  
        margin-bottom: 44px;

        strong {
            font-size: 40px;
            font-weight: 700;
        }

        h4 {
            font-weight: 600;
            margin-bottom: 0;
        }

        p {
            margin-bottom: 0;
        }
    }

    .text-blue-highlight {
        color: #01E4FF;
        font-size: 34px;
        font-weight: 700;
        line-height: 30px;
        margin-bottom: 55px;
    }

    @media (max-width: 1167px) {
        .main-section {
            flex-direction: column;
            height: auto;
            margin-top: 100px;
        }

        .section-info {
            width: 100%;
            padding: 120px 70px;
        }

        .section-titles {
            margin-bottom: 46px;
        }
    }
}