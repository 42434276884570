#section-2 {
    .main-section {
        height: 100%;
        min-height: 100vh;
        display: flex;
        align-items: center;
        padding-bottom: 56px;
    }

    .contents-row {
        display: flex;
        align-items: flex-end;
        position: relative;
        height: 460px;
    }

    .section-info {
        height: 408px;
        background-color: #191F25;

        .left-overlay {
            position: absolute;
            height: 100%;
            width: 300px;
            top: 0;
            left: -300px;
            background-color: #191F25;
        }
    }

    .section-image,
    .section-info {
        align-items: center;
        display: flex;
        position: relative;
    }

    .section-info .title {
        font-size: 24px;
        font-weight: 600;
        position: relative;

        img {
            position: absolute;
            top: -13px;
            left: -8px;
        }
    }

    .section-info .description {
        color: #BAC2CB;
        font-size: 16px;
        line-height: 30px;
    }

    .section-info>div {
        width: 60%;
        margin: 0 auto;
    }

    img {
        max-height: 120%;
        object-fit: contain;
    }

    @media (max-width: 1167px) {
        .main-section {
            height: auto;
            min-height: auto;
            margin-top: 90px;
        }

        .contents-row {
            flex-direction: column;
            height: 100%;
            width: 100%;
            background-color: #191F25;
            padding-top: 100px;
            align-items: flex-start;
        }

        .section-info {
            padding-bottom: 100px;
            height: fit-content;

            .left-overlay {
                display: none;
            }

            div {
                width: 100%;
                padding: 0 40px;
            }
        }

        .section-image {
            width: 100%;
        }
    }

    @media (max-width: 650px) {
        .section-image img {
            width: 100%;
        }
    }
}