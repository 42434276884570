#section-1 {
    .main-section {
        height: 752px;
        display: flex;
        align-items: flex-end;
    }

    .logo-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 168px;
    }

    .cover-image-wrapper {
        width: 80%;
        background: rgb(25, 31, 37);
        background: linear-gradient(180deg, rgba(25, 31, 37, 1) 7%, rgba(25, 31, 37, 1) 46.8%, rgba(14, 18, 22, 1) 46.8%, rgba(14, 18, 22, 1) 100%);
        border-bottom-left-radius: 200px;
        position: relative;
        margin-left: auto;
    }

    .cover-image {
        margin-left: 150px;
        z-index: 10;
    }

    .image-caption {
        background: #01E4FF;
        margin-bottom: 168px;
        padding: 36px;
        border-top-left-radius: 40px;
        width: 347px;
        position: absolute;
        left: -154px;
        bottom: 0;

        p {
            margin-bottom: 0;
            font-size: 18px;
            font-weight: 500;
            line-height: 24px;
            color: #0E1216;
        }
    }

    @media (max-width: 1167px) {
        .main-section {
            flex-direction: column;
            position: relative;
            height: 805px;
            padding-top: 100px;
        }

        .logo-container {
            width: 100%;
            position: absolute;
            z-index: 10;
            margin-top: 60px;
        }

        .cover-container {
            width: 100%;
        }

        .cover-image-wrapper {
            margin-left: 0;
            width: 100%;
            justify-content: center;
            align-items: center;
            border-bottom-left-radius: 100px;
        }

        .cover-image {
            margin-right: 12px;
            margin-left: 0;
        }

        .image-caption {
            position: inherit;
            max-height: 120px;
            margin-bottom: -296px;
            margin-right: -50px;
            left: auto;
            margin-left: 10px;
            max-height: fit-content;
        }
    }

    @media (max-width: 650px) {
        .cover-image {
            margin-right: -65px;
        }

        .logo-container {
            img {
                height: 35px;
            }

            .logo-title {
                font-size: 16px;
            }
        }

        .image-caption {
            padding: 12px 50px 12px 25px;
            border-top-left-radius: 20px;
        }
    }
}