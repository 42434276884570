#section-3 {
    .main-section {
        height: 690px;
        align-items: flex-end;
    }

    .section-info {
        height: 408px;
        background: #0E1216;
        display: flex;
        justify-content: center;
        padding: 0 150px;
        flex-direction: column;

        .description {
            color: #BAC2CB;
            font-size: 16px;
            line-height: 30px;
        }
    }



    @media (max-width: 1440px) {
        .main-section {
            height: fit-content;
            margin-top: 100px;
        }

        .section-info {
            padding: 0 50px;
        }
    }

    @media (max-width: 1167px) {
        .main-section {
            flex-direction: column;
        }
    }

    @media (max-width: 650px) {
        .cover-image {
            width: 100%;
        }

        .section-info {
            padding: 0 40px;
        }
    }
}