#section-8 {
    .main-section {
        padding: 0 120px;
        display: flex;
        height: 100vh;
        justify-content: center;
        flex-direction: column;
    }

    .section-titles {
        margin-bottom: 84px;
    }

    .item-row {
        gap: 15px;
        flex-wrap: wrap;
        position: relative;
        padding-top: 3%;

        .steps-check-mobile {
            display: none;
        }

        .steps-check-desktop {
            position: absolute;
            left: 0;
            top: 0;
            width: 85%;
        }
    }

    .item {
        flex: 1;
        height: 100%;
        display: inherit;

        .item-box {
            border-radius: 20px;
            background: #0E1216;
            width: 100%;
            min-height: 185px;
            margin-top: 28px;
            padding: 25px;

            p {
                color: #BAC2CB;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 0;
            }
        }
    }

    @media (max-width: 1167px) {
        .main-section {
            height: auto;
            padding: 0 30px;
            margin-top: 100px;
        }

        .item-row {
            flex-direction: column;
            padding-left: 70px;
            padding-top: 0;
            gap: 20px;

            .steps-check-mobile {
                display: block;
                position: absolute;
                top: 0;
                left: 0;
                height: 86%;
            }

            .steps-check-desktop {
                display: none;
            }
        }

        .item {
            width: 100%;
            .item-box {
                margin-top: 0;
                width: 100%;
            }
        }
    }
}